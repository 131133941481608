<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col class="col-md-6 offset-md-3">
          <div class="mb-2 d-flex justify-space-between">
            <BackLink />
            <h2 class="text-h5 grey--text text--darken-2">Setting</h2>
          </div>
          <v-form @submit="submit">
            <v-expansion-panels
              v-model="panel"
              class="mb-4"
              mandatory
              multiple
              accordion
              flat
              tile
            >
              <v-expansion-panel>
                <v-expansion-panel-header>
                  General
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <input-field
                    v-model="data.app_name"
                    text
                    label="Nama RS"
                    :uppercase="false"
                  />
                  <input-field
                    v-model="data.unit"
                    text
                    label="Unit"
                    :uppercase="false"
                  />
                  <input-field
                    v-model="data.server_url"
                    text
                    label="Server URL"
                    :uppercase="false"
                  >
                    <template v-slot:append>
                      <v-btn small class="pb-2" icon tile>
                        <v-avatar tile size="17">
                          <v-img
                            max-height="17"
                            max-width="17"
                            src="@/assets/images/scan_icon.png"
                            @click.stop="onScan(data.server_url)"
                          />
                        </v-avatar>
                      </v-btn>
                    </template>
                  </input-field>
                  <v-switch
                    v-model="data.print_server"
                    :label="`Print Server: ${
                      data.print_server ? 'Enable' : 'Disable'
                    }`"
                    dense
                  />
                  <v-switch
                    v-model="data.gtm"
                    :label="`Tracking: ${data.gtm ? 'Enable' : 'Disable'}`"
                    dense
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Form
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <input-field
                    class="mb-3"
                    v-model="data.defaultData"
                    select
                    :itemSelect="['pasien', 'inventaris', 'staff']"
                    label="Default Data"
                  />
                  <v-expansion-panels class="mb-2">
                    <draggable
                      class="layer-list"
                      tag="div"
                      v-model="form"
                      v-bind="dragOptions"
                      :sort="false"
                      @start="drag = true"
                      @end="drag = false"
                      style="width: 100%"
                      handle=".handle"
                    >
                      <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                      >
                        <v-expansion-panel
                          v-for="(item, i) in form"
                          :key="i + 1"
                        >
                          <v-expansion-panel-header class="text-subtitle-1">
                            <div class="d-flex">
                              <v-icon class="handle mr-2" small
                                >mdi-drag-horizontal-variant</v-icon
                              >
                              <v-icon
                                class="mr-2"
                                small
                                @click.stop="form.splice(i, 1)"
                                >mdi-close-circle</v-icon
                              >
                              {{ item.label }}
                            </div>
                            <template v-slot:actions>
                              <v-avatar
                                color="#00cccc"
                                size="16"
                                max-width="16"
                                max-height="16"
                                tile
                                class="col-2 pa-0 ml-auto"
                              >
                                <v-icon small color="white"
                                  >mdi-menu-down</v-icon
                                >
                              </v-avatar>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <input-field
                              v-model="item.label"
                              text
                              label="Label"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.model"
                              text
                              label="Model"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.hint"
                              text
                              label="Hint"
                              :uppercase="false"
                            />
                            <input-field
                              v-model="item.type"
                              select
                              :itemSelect="[
                                'text',
                                'select',
                                'autocomplete',
                                'textarea',
                                'upload',
                                'date',
                                'number',
                                'custom',
                                'hidden',
                                'popup',
                              ]"
                              label="Type"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="
                                item.type === 'text' ||
                                item.type === 'autocomplete' ||
                                item.type === 'textarea'
                              "
                              v-model="item.scanner"
                              select
                              :itemSelect="[
                                { text: 'Enable', value: true },
                                { text: 'Disable', value: false },
                              ]"
                              label="Input Scanner"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="item.type !== 'upload'"
                              v-model="item.col"
                              text
                              label="Cols"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="
                                item.type === 'select' ||
                                item.type === 'autocomplete'
                              "
                              v-model="item.items"
                              text
                              label="Items"
                              :uppercase="false"
                            />
                            <input-field
                              v-if="
                                item.type === 'select' ||
                                item.type === 'autocomplete'
                              "
                              v-model="item.multiple"
                              select
                              label="Multiple"
                              :uppercase="false"
                              :itemSelect="[true, false]"
                            />
                            <template v-else-if="item.type === 'upload'">
                              <input-field
                                v-model="item.width"
                                text
                                label="Width"
                                :uppercase="false"
                              />
                              <input-field
                                v-model="item.height"
                                text
                                label="height"
                                :uppercase="false"
                              />
                            </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </transition-group>
                    </draggable>
                  </v-expansion-panels>
                  <div class="text-center">
                    <v-btn
                      depressed
                      dark
                      small
                      color="primary"
                      @click="addForm()"
                    >
                      Tambah Form
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Resep
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    false-value="disable"
                    true-value="enable"
                    v-model="resep.enable"
                    :label="`Resep: ${resep.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="resep.aturan"
                    text
                    label="Aturan Penggunaan"
                  />
                  <input-field
                    v-model="resep.waktu"
                    text
                    label="Waktu Penggunaan"
                  />
                  <input-field v-model="resep.catatan" text label="Catatan" />
                  <input-field
                    v-model="resep.satuan"
                    text
                    label="Default Satuan"
                  />
                  <input-field
                    v-model="resep.enable_tipe"
                    select
                    multiple
                    :itemSelect="[
                      { text: 'IP Oral', value: 'etiket_putih' },
                      { text: 'IP Luar', value: 'etiket_biru' },
                      { text: 'Racikan', value: 'racikan' },
                      { text: 'Racikan Biru', value: 'racikan_biru' },
                      { text: 'UDD', value: 'udd' },
                      { text: 'UDD Biru', value: 'udd_biru' },
                      { text: 'Infus', value: 'infus' },
                      { text: 'Injeksi', value: 'injeksi' },
                    ]"
                    label="Enable Tipe"
                    :clearable="false"
                    tipe
                  />
                  <input-field
                    v-model="resep.tipe"
                    select
                    :itemSelect="[
                      { text: 'IP Oral', value: 'etiket_putih' },
                      { text: 'IP Luar', value: 'etiket_biru' },
                      { text: 'UDD', value: 'udd' },
                      { text: 'UDD Biru', value: 'udd_biru' },
                      { text: 'Infus', value: 'infus' },
                      { text: 'Injeksi', value: 'injeksi' },
                    ]"
                    label="Default Tipe"
                    :clearable="false"
                    tipe
                  />
                  <input-field v-model="resep.qty" text label="Default Qty" />
                  <input-field
                    v-model="resep.udd_by"
                    select
                    :itemSelect="[
                      { text: 'Default', value: 'default' },
                      { text: 'Waktu', value: 'waktu' },
                      { text: 'Waktu Split', value: 'waktu_split' },
                    ]"
                    label="Default UDD"
                    :clearable="false"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="resep.waktu_fleksibel"
                    label="Waktu Fleksibel"
                    color=""
                    dense
                  />
                  <v-card v-if="!resep.waktu_fleksibel" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show = !show">UDD Putih</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                          show ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="waktu_udd"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in waktu_udd"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="waktu_udd.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.waktu }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu Pemberian"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.dosis"
                                    text
                                    label="Dosis"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addWaktu('putih')"
                              >
                                Tambah Waktu
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>

                  <v-card v-if="!resep.waktu_fleksibel" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show2 = !show2">UDD Biru</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show2 = !show2">
                        <v-icon>{{
                          show2 ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show2">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="waktu_udd_biru"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in waktu_udd_biru"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="waktu_udd_biru.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.waktu }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu Pemberian"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.dosis"
                                    text
                                    label="Dosis"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addWaktu('biru')"
                              >
                                Tambah Waktu
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>

                  <v-switch
                    class="text-normal"
                    v-model="resep.signa_format"
                    label="Signa Format"
                    color=""
                    dense
                  />
                  <v-card v-if="resep.signa_format" class="mx-auto my-2">
                    <v-card-actions class="pa-1">
                      <v-btn text @click="show3 = !show3">List Signa</v-btn>

                      <v-spacer></v-spacer>

                      <v-btn icon @click="show3 = !show3">
                        <v-icon>{{
                          show3 ? "mdi-chevron-up" : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <v-card-text v-show="show3">
                        <v-expansion-panels class="mb-2">
                          <draggable
                            class="layer-list"
                            tag="div"
                            v-model="signa"
                            v-bind="dragOptions"
                            :sort="false"
                            @start="drag = true"
                            @end="drag = false"
                            style="width: 100%"
                            handle=".handle"
                          >
                            <transition-group
                              type="transition"
                              :name="!drag ? 'flip-list' : null"
                            >
                              <v-expansion-panel
                                v-for="(item, i) in signa"
                                :key="i + 1"
                              >
                                <v-expansion-panel-header
                                  class="text-subtitle-1"
                                >
                                  <div class="d-flex">
                                    <v-icon class="handle mr-2" small
                                      >mdi-cursor-move</v-icon
                                    >
                                    <v-icon
                                      class="mr-2"
                                      small
                                      @click.stop="signa.splice(i, 1)"
                                      >mdi-close-circle</v-icon
                                    >
                                    {{ item.aturan }}
                                  </div>
                                  <template v-slot:actions>
                                    <v-avatar
                                      color="#00cccc"
                                      size="16"
                                      max-width="16"
                                      max-height="16"
                                      tile
                                      class="col-2 pa-0 ml-auto"
                                    >
                                      <v-icon small color="white"
                                        >mdi-menu-down</v-icon
                                      >
                                    </v-avatar>
                                  </template>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <input-field
                                    v-model="item.aturan"
                                    text
                                    label="Aturan"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.waktu"
                                    text
                                    label="Waktu"
                                    :uppercase="false"
                                  />
                                  <input-field
                                    v-model="item.format"
                                    text
                                    label="Format"
                                    :uppercase="false"
                                  />
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </transition-group>

                            <div slot="footer" class="text-center">
                              <v-btn
                                depressed
                                dark
                                small
                                color="primary"
                                @click="addSigna()"
                              >
                                Tambah Signa
                              </v-btn>
                            </div>
                          </draggable>
                        </v-expansion-panels>
                      </v-card-text>
                    </v-expand-transition>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Gizi
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.gizi"
                    :label="`Input Gizi: ${data.gizi}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.giziObject"
                    autocomplete2
                    multiple
                    :itemSelect="giziObject"
                    itemText="obat"
                    label="Default Waktu Makan"
                    :clearable="false"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  API
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.api.enable"
                    :label="`API Pasien: ${data.api.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.api.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Input"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.apiFarmasi.enable"
                    :label="`API Farmasi: ${data.apiFarmasi.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-model="data.apiFarmasi.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Input"
                  />
                  <input-field
                    v-model="data.apiFarmasi.data"
                    select
                    multiple
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Data"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Print Data
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.custom_print"
                    :label="`Custom Print: ${
                      data.custom_print ? 'Enable' : 'Disable'
                    }`"
                    dense
                  />
                  <v-expansion-panels v-if="data.custom_print" class="my-2">
                    <draggable
                      class="layer-list"
                      tag="div"
                      v-model="print_data"
                      v-bind="dragOptions"
                      :sort="false"
                      @start="drag = true"
                      @end="drag = false"
                      style="width: 100%"
                      handle=".handle"
                    >
                      <transition-group
                        type="transition"
                        :name="!drag ? 'flip-list' : null"
                      >
                        <v-expansion-panel
                          v-for="(item, i) in print_data"
                          :key="i + 1"
                          class="white pa-2 mb-2 rounded elevation-3"
                        >
                          <v-expansion-panel-header class="text-subtitle-1">
                            <div class="d-flex">
                              <v-icon class="handle mr-2" small
                                >mdi-drag-horizontal-variant</v-icon
                              >
                              <v-icon
                                class="mr-2"
                                small
                                @click.stop="print_data.splice(i, 1)"
                                >mdi-close-circle</v-icon
                              >
                              {{ item.nama }}
                            </div>
                            <template v-slot:actions>
                              <v-avatar
                                color="#00cccc"
                                size="16"
                                max-width="16"
                                max-height="16"
                                tile
                                class="col-2 pa-0 ml-auto"
                              >
                                <v-icon small color="white"
                                  >mdi-menu-down</v-icon
                                >
                              </v-avatar>
                            </template>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="pa-2">
                            <input-field
                              v-model="item.nama"
                              text
                              label="Nama Berkas"
                              :uppercase="false"
                            />
                            <input-field
                              class="mt-2"
                              v-model="selectlabel"
                              @change="
                                selectlabel && tambahLabel(selectlabel, item)
                              "
                              autocomplete2
                              :itemSelect="label"
                              itemText="name"
                              placeholder="Cari Label"
                              solo
                              flat
                              background="#efefef"
                              autofocus
                            >
                              <template v-slot:prepend-inner>
                                <v-icon size="x-large">mdi-card-search</v-icon>
                              </template>
                              <template v-slot:selection />
                            </input-field>
                            <v-expansion-panels class="mb-2">
                              <draggable
                                class="layer-list"
                                tag="div"
                                v-model="item.label"
                                v-bind="dragOptions"
                                :sort="false"
                                @start="drag = true"
                                @end="drag = false"
                                style="width: 100%"
                                handle=".handle"
                              >
                                <transition-group
                                  type="transition"
                                  :name="!drag ? 'flip-list' : null"
                                >
                                  <v-expansion-panel
                                    v-for="(itemx, i) in item.label"
                                    :key="i + 1"
                                  >
                                    <v-expansion-panel-header
                                      class="text-subtitle-1"
                                    >
                                      <div class="d-flex">
                                        <v-icon class="handle mr-2" small
                                          >mdi-drag-horizontal-variant</v-icon
                                        >
                                        <v-icon
                                          class="mr-2"
                                          small
                                          @click.stop="item.label.splice(i, 1)"
                                          >mdi-close-circle</v-icon
                                        >
                                        {{ itemx.name }}
                                      </div>
                                      <template v-slot:actions>
                                        <v-avatar
                                          color="#00cccc"
                                          size="16"
                                          max-width="16"
                                          max-height="16"
                                          tile
                                          class="col-2 pa-0 ml-auto"
                                        >
                                          <v-icon small color="white"
                                            >mdi-menu-down</v-icon
                                          >
                                        </v-avatar>
                                      </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>
                                      <v-autocomplete
                                        v-model="itemx.content.printer"
                                        :items="printer"
                                        label="Printer"
                                        itemText="name"
                                        dense
                                        hide-details="auto"
                                      />
                                    </v-expansion-panel-content>
                                  </v-expansion-panel>
                                </transition-group>
                              </draggable>
                            </v-expansion-panels>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </transition-group>

                      <div slot="footer" class="text-center">
                        <v-btn
                          depressed
                          dark
                          small
                          color="primary"
                          @click="addPrint()"
                        >
                          Tambah Print Data
                        </v-btn>
                      </div>
                    </draggable>
                  </v-expansion-panels>

                  <v-switch
                    class="text-normal"
                    v-model="data.custom.enable"
                    :label="`Custom Button: ${data.custom.enable}`"
                    color=""
                    dense
                  ></v-switch>
                  <input-field
                    v-if="data.custom.enable === true"
                    v-model="data.custom.label"
                    text
                    :uppercase="false"
                    label="Label"
                  />
                  <input-field
                    v-if="data.custom.enable === true"
                    v-model="data.custom.icon"
                    text
                    :uppercase="false"
                    label="Icon"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Pengaturan Lain
                  <template v-slot:actions>
                    <v-avatar
                      color="#00cccc"
                      size="16"
                      max-width="16"
                      max-height="16"
                      tile
                      class="col-2 pa-0 ml-auto"
                    >
                      <v-icon small color="white">mdi-menu-down</v-icon>
                    </v-avatar>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-switch
                    class="text-normal"
                    v-model="data.print_portable"
                    :label="`Print Portable: ${
                      data.print_portable ? 'Enable' : 'Disable'
                    }`"
                    dense
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.antrian"
                    :label="`Antrian: ${data.antrian}`"
                    color=""
                    dense
                  ></v-switch>
                  <v-switch
                    class="text-normal"
                    v-model="data.filter.enable"
                    :label="`Filter: ${data.filter.enable}`"
                    color=""
                    dense
                  />
                  <input-field
                    v-if="data.filter.enable"
                    v-model="data.filter.trigger"
                    select
                    :itemSelect="form"
                    itemText="model"
                    :uppercase="false"
                    label="Trigger Filter"
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.multi_print"
                    :label="`Multi Print: ${data.multi_print}`"
                    color=""
                    dense
                  />
                  <v-switch
                    class="text-normal"
                    v-model="data.exporter"
                    :label="`Exporter: ${data.exporter}`"
                    color=""
                    dense
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-card-actions class="px-0">
              <v-btn
                block
                depressed
                :loading="isLoading"
                color="#00cccc"
                class="white--text"
                @click="submit"
              >
                SIMPAN
              </v-btn>
            </v-card-actions>
          </v-form>
          <div class="text-center text-body-2">v{{ version }}</div>
        </v-col>
      </v-row>
    </v-container>
    <scanner ref="scanner" />
  </v-main>
</template>

<script>
import InputField from "@/components/InputField";
import draggable from "vuedraggable";
import Scanner from "@/components/Scanner";

const version = require("../../package.json").version;

export default {
  name: "Setting",
  components: { InputField, Scanner, draggable },
  data() {
    return {
      isLoading: false,
      panel: [],
      form: this.$store.state.settings.form || [],
      waktu_udd: this.$store.state.settings.resep.waktu_udd || [],
      waktu_udd_biru: this.$store.state.settings.resep.waktu_udd_biru || [],
      signa: this.$store.state.settings.resep.signa || [],
      print_data: this.$store.state.settings.print_data || [],
      drag: false,
      show: false,
      show2: false,
      show3: false,
      server_url: this.$store.state.settings.server_url,
      version: version,
      url: this.$store.state.settings.server_url || "http://localhost:3030",
      label: [],
      printer: [],
      selectlabel: null,
    };
  },
  computed: {
    data: {
      get() {
        return this.$store.state.settings ? this.$store.state.settings : {};
      },
      set(val) {
        this.$store.commit("basic", { key: "settings", value: val });
      },
    },
    resep: {
      get() {
        return this.$store.state.settings.resep
          ? this.$store.state.settings.resep
          : {};
      },
      set(val) {
        this.$store.commit("setting", { key: "resep", value: val });
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
    giziObject() {
      return [
        {
          obat: "Makan Pagi",
          tipe: "etiket_putih",
        },
        {
          obat: "Makan Siang",
          tipe: "etiket_putih",
        },
        {
          obat: "Makan Sore",
          tipe: "etiket_putih",
        },
        {
          obat: "Snack Pagi",
          tipe: "etiket_biru",
        },
        {
          obat: "Snack Sore",
          tipe: "etiket_biru",
        },
      ];
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    tambahLabel(label, item) {
      let data = {
        _id: label._id,
        name: label.name,
        content: label.content.page,
      };
      item.label.push(data);
      this.$nextTick(() => {
        this.selectlabel = null;
      });
    },
    getData() {
      this.$store.commit("loading");
      Promise.all(
        [
          `${this.url}/api/label?content.page.status=enable&$select[]=name&$select[]=_id&$select[]=content`,
          `${this.url}/printers`,
        ].map((url) => this.axios.get(url))
      )
        .then(([{ data: label }, { data: printer }]) => {
          console.log({ label, printer });
          if (label) {
            this.label = label;
          }
          if (printer) {
            this.printer = printer;
          }
        })
        .catch((err) => {
          console.log(err.message);
        })
        .finally(() => {
          this.$store.commit("loading");
        });
    },
    getFormObject() {
      return {
        model: "input",
        label: "Input Baru",
        hint: null,
        type: "text",
        items: null,
        col: "12",
        scanner: false,
      };
    },
    addForm() {
      this.form.push(this.getFormObject());
    },
    addWaktu(tipe) {
      if (tipe === "putih") {
        this.waktu_udd.push({ waktu: "Pagi 07:00", dosis: null });
      } else {
        this.waktu_udd_biru.push({ waktu: "Pagi 07:00", dosis: null });
      }
    },
    addSigna() {
      this.signa.push({
        aturan: "3 x Oral",
        waktu: "06:00 - 14:00 - 22:00",
        format: "{aturan} {dosis} {keterangan}",
      });
    },
    addPrint() {
      this.print_data.push({
        nama: "Default",
        label: [],
      });
    },
    submit() {
      this.resep.waktu_udd = this.waktu_udd;
      this.resep.waktu_udd_biru = this.waktu_udd_biru;
      this.resep.signa = this.signa;
      this.data.form = this.form;
      this.data.resep = this.resep;
      this.data.print_data = this.print_data;
      this.server_url = this.data.server_url;

      this.$store.commit("basic", { key: "settings", value: this.data });
      this.$root.snackbar.show({
        text: "Setting berhasil disimpan",
      });
    },
    onScan(model) {
      this.$refs.scanner
        .open()
        .then((val) => {
          this.data[model] = val;
        })
        .catch((res) => {
          console.log(res);
        });
    },
  },
  watch: {
    server_url() {
      window.location.reload();
    },
  },
};
</script>
