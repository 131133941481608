<template>
  <v-dialog
    scrollable
    fullscreen
    v-model="dialog"
    @keydown.esc="cancel()"
  >
    <v-card tile class="white" id="border">
      <StreamBarcodeReader
        v-if="scanner === 'auto'"
        @decode="(a, b, c) => onDecode(a, b, c)"
        @loaded="() => onLoaded()"
      />
      <!-- <qrcode-stream @decode="onDecode" :camera="scanner" @init="onInit"/> -->
      <!-- <canvas id="videoCanvas"></canvas> -->
      <!-- <div id="webCamera" width="100vw"></div> -->
    </v-card>
    <v-btn
      depressed
      fab
      small
      dark
      top
      left
      absolute
      @click="cancel()"
      class="mt-10"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
// @ is an alias to /src
import { StreamBarcodeReader } from "vue-barcode-reader";

export default {
  name: 'home',
  components: {
    StreamBarcodeReader
  },
  data () {
    return {
      dialog: false,
      scanner: 'auto',
      resolve : null,
      reject : null,
      result: [],
    }
  },
  methods: {
    open(){
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
        this.dialog = true
        this.scanner = 'auto'
        this.result = null
      })
    },
    async onDecode (result) {
      this.beep();
      this.result = result
      this.accept(this.result);
    },
    onLoaded() {
      let border = '<div style="position: absolute; background-color: rgb(5, 211, 230); width: 40px; height: 3px; top: -3px; left: 0px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 40px; height: 3px; top: -3px; right: 0px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 40px; height: 3px; bottom: -3px; left: 0px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 40px; height: 3px; bottom: -3px; right: 0px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 3px; height: 45px; top: -3px; left: -3px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 3px; height: 45px; bottom: -3px; left: -3px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 3px; height: 45px; top: -3px; right: -3px;"></div><div style="position: absolute; background-color: rgb(5, 211, 230); width: 3px; height: 45px; bottom: -3px; right: -3px;"></div>'
      document.getElementsByClassName("overlay-element")[0].innerHTML = border

      let logo = document.createElement('div')
      logo.innerHTML = `<img src="${this.$store.state.settings.server_url || 'http://localhost:3030'}/assets/img/id-grow2.png"/>`
      logo.className = 'align-center d-flex justify-center logo'
      document.getElementsByClassName("scanner-container")[0].prepend(logo);
      document.getElementsByClassName("laser")[0].remove();
    },
    accept(val) {
      this.resolve(val);
      this.dialog = false;
      this.scanner = 'off'
    },
    cancel() {
      this.reject('closed');
      this.dialog = false;
      this.scanner = 'off'
    },
    beep() {
      let audio = new Audio(`${this.$store.state.settings.server_url || 'http://localhost:3030'}/assets/beep.mp3`);
      audio.play();
    }
  },
}
</script>
<style scoped>
  .scanner-container, .qrcode-stream-wrapper {
    /* height: 50vh !important; */
    overflow: hidden;
  }
  .scanner-container :deep(video), .qrcode-stream-wrapper :deep(video) {
    width:100vw;
    height:100vh;
    object-fit:cover;
    z-index:-1;
    /* position:absolute; */
  }
  .scanner-container :deep(.overlay-element), .qrcode-stream-wrapper :deep(.qrcode-stream-overlay) {
    background: none;
    height: 100vh;
    border-top-width: calc((100vh - 350px) / 2);
    border-bottom-width: calc((100vh - 350px) / 2);
    border-left-width: calc((100vw - 350px) / 2);
    border-right-width: calc((100vw - 350px) / 2);
    border-style: solid;
    border-color: #1e1e1e80;
    box-sizing: border-box;
    inset: 0px;
    clip-path: unset !important
  }
  .scanner-container :deep(.laser) {
    top: calc((100vh - 350px) / 2);
    width: 350px;
    margin-left: calc((100vw - 350px) / 2);
    -webkit-animation: scanning 3s infinite;
    animation: scanning 3s infinite;
  }
  @keyframes scanning {
      50% {
          -webkit-transform: translateY(350px);
          transform: translateY(350px);
      }
  }
  .scanner-container :deep(.logo) {
    position: absolute;
    width: 100%;
    height: 100px;
  }
</style>
